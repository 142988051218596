var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user && _vm.user.user_type === "user"
    ? _c(
        "a-menu",
        { attrs: { theme: "dark", mode: "inline" } },
        [
          _c(
            "a-menu-item-group",
            { key: "g2", staticClass: "gx-menu-group" },
            [
              _c(
                "a-menu-item",
                { key: "dashboard", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _c("span", [_vm._v("My Calendar")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "my_clubs", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/my-clubs" } },
                    [
                      _c("a-icon", { attrs: { type: "trophy" } }),
                      _c("span", [_vm._v("My Clubs")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "my_billing", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/my-billing" } },
                    [
                      _c("a-icon", { attrs: { type: "euro" } }),
                      _c("span", [_vm._v("My Billing")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "my_profile", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/my-profile" } },
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _c("span", [_vm._v("My Profile")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "logout", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/logout" } },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _c("span", [_vm._v("Logout")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }