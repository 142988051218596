var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { attrs: { id: "gx-layout" } },
    [
      _c(
        "a-layout-sider",
        {
          staticClass: "gx-app-sidebar gx-layout-sider-dark",
          attrs: { trigger: null, collapsible: "" },
          model: {
            value: _vm.collapsed,
            callback: function($$v) {
              _vm.collapsed = $$v
            },
            expression: "collapsed"
          }
        },
        [
          _c(
            "div",
            { staticClass: "gx-layout-sider-header" },
            [
              _c("div", { staticClass: "gx-linebar" }, [
                _c("i", {
                  staticClass: "gx-icon-btn icon gx-text-white",
                  class: {
                    "icon-menu-unfold": _vm.collapsed,
                    "icon-menu-fold": !_vm.collapsed
                  },
                  attrs: { type: _vm.collapsed ? "menu-unfold" : "menu-fold" },
                  on: {
                    click: function($event) {
                      return _vm.toggleSidebar()
                    }
                  }
                })
              ]),
              _c(
                "router-link",
                { staticClass: "gx-site-logo", attrs: { to: "/" } },
                [
                  _c("img", {
                    staticStyle: { "max-height": "50px" },
                    attrs: {
                      alt: "SubsApp",
                      src: require("@/assets/regular-logo.png")
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c("menu-club"),
          _c("menu-user"),
          _c("menu-admin")
        ],
        1
      ),
      _c(
        "a-layout",
        [
          _c(
            "a-layout-header",
            [
              _c(
                "div",
                {
                  staticClass: "gx-d-lg-none gx-d-block gx-linebar gx-mr-3",
                  on: {
                    click: function($event) {
                      return _vm.toggleSidebar()
                    }
                  }
                },
                [_c("i", { staticClass: "gx-icon-btn icon icon-menu" })]
              ),
              _c(
                "router-link",
                {
                  staticClass: "gx-d-block gx-d-lg-none gx-pointer",
                  attrs: { to: "/" }
                },
                [
                  _c("img", {
                    staticStyle: { "max-height": "50px" },
                    attrs: {
                      alt: "LeadSurge",
                      src: require("@/assets/regular-logo.png")
                    }
                  })
                ]
              ),
              _vm.user && _vm.user.user
                ? _c(
                    "ul",
                    { staticClass: "gx-header-notifications gx-ml-auto" },
                    [
                      _c(
                        "li",
                        { staticClass: "gx-user-nav" },
                        [
                          _c(
                            "a-row",
                            { attrs: { type: "flex" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ant-avatar gx-avatar gx-pointer ant-avatar-circle ant-avatar-image"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "",
                                      src: _vm.userProfileAvatar
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { to: "/my-profile", tag: "div" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "gx-user-nav--name" },
                                    [
                                      _vm._v(
                                        "\n                Hi " +
                                          _vm._s(_vm.user.user.first_name) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-layout-content",
            { staticClass: "gx-layout-content" },
            [
              _vm.$route.path !== "/" &&
              _vm.$route.path !== "/admin" &&
              _vm.$route.path !== "/dashboard"
                ? _c(
                    "a-button",
                    {
                      staticClass: "back-button",
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        GO BACK " +
                          _vm._s(_vm.$router.path) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _c("router-view")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mobile-menu" },
        [
          _c(
            "a-drawer",
            {
              attrs: {
                placement: "left",
                "wrap-class-name": "mobile-menu",
                visible: _vm.collapsed
              },
              on: {
                close: function($event) {
                  return _vm.hideSidebar()
                }
              }
            },
            [_c("menu-club"), _c("menu-user"), _c("menu-admin")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }